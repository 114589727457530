.FeaturedClients {}
.ClientsList{
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.SectionTitle{
    color: #737373;
    text-align: center;
    font-family: 'productsans-black';
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.8rem;
    letter-spacing: 0.1125rem;
    text-transform: uppercase;
}
.ClientImage{
    max-width: 50px;
}

@media (min-width: 375px) and (max-width: 768px) {

    .ClientsList{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }
    
    .SectionTitle{
        color: #737373;
        text-align: center;
        font-family: 'productsans-black';
        font-size: 1rem;
        font-style: normal;
        font-weight: normal;
        line-height: 2.8rem;
        letter-spacing: 0.1125rem;
        text-transform: uppercase;
    }




}
 