.DealPageLeaderboard {
    border-bottom: 1px solid #d9d9d9;
}

.leaderboardcontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.5rem;
    justify-content: center;
    gap:1rem
}

.interstitial{
    color: #000;
text-align: center;
font-family: Arial;
font-size: 1rem;
font-style: normal;
font-weight: normal;
line-height: normal;
letter-spacing: 0.04688rem;
}

.rewardslevelcontainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.25rem;
    border-radius: 8px;
    background: rgb(235 241 247 / 98%);
    padding: 0.5rem;
}
.rewardslevelholder{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    
}
.rewardslevel{
    color: #7B7D7E;
    text-align: center;
    font-family: "productsans-black";
    font-size: 0.5rem;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01875rem;
    text-transform: uppercase;
    padding: 0.35rem;
   
}
.rewardslevelname{
    color: #000;
    font-family: "productsans-black";
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;

    letter-spacing: 0.0375rem;
    text-transform: capitalize;
}

.rewardsgenericcontainer{
display: flex;
flex-direction: column;
align-items: center;
padding-left: 0.5rem;
padding-right: 0.5rem;
}
.rewardsgenericamount{
color: #000;
text-align: center;
font-family: "productsans-black";
font-size: 1.25rem;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: 0.0625rem;

}
.rewardsgenericdescription{
    color: rgba(0, 0, 0, 0.50);
    font-family: "productsans-black";
    font-size: 0.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 0.625rem;
    letter-spacing: 0.0375rem;
    text-transform: uppercase;
}