
.PromoItem {
  font-family: "productsans-regular", Helvetica, Arial, sans-serif;
  color: #404040;
  font-size: 14px;
  font-weight: normal; 
  line-height: 22px;
  letter-spacing: .4px;
    background: #bfbfbf0f; 
  padding-top: 15px;
}

.campaignDetails{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 100px;
    font-family: 'productsans-regular';
    font-size: x-small;
    color: #575757;
}

.campaignDetails button{
  
    font-size: x-small;
    text-decoration: underline;
    color: #575757;
}

.promotop{
  height: 120px;
  border-radius:4px 4px 0px 0px;
  background-color: #44523f;
  overflow: hidden;
  transition: all .2s ease-in-out;
}

.card {
  display: inline-block;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
  width: 90%;
}

.card:hover {
  /*box-shadow: 0 5px 22px 0 rgba(0,0,0,.25);*/
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  margin-bottom: 54px;
}

.image {
  height: 120px;
  opacity: 100;
  overflow: hidden;
  transition: all .2s ease-in-out;
  border-radius:4px 4px 0px 0px;
}

.image:hover,
.card:hover .image {
  height: 200px;
  opacity: 1;
}

.text {
  background: #FFF;
  padding: 15px;
  min-height: 90px;
  text-align: left;
}

.text p {
  margin-bottom: 0px;
}
.text h3 {
  margin-bottom: 0px;
}

.text button {
  min-width: 100px;
  min-height: 36px;
  border-radius: 18px;
  background-color: #113d4d;
  color: white;
  font-weight: bold;
  border-width: 0px;
  margin: 30px 10px 10px 0px;
}


.fab {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  margin-top: -50px;
  right: 20px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, .3);
  color: #fff;
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  background: #0066A2;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
}

.fab:hover {
  background: #549D3C;
  cursor: pointer;
  -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.buttonContainer {
  display: inline-flex;
  gap: 15px;;  
}
.buttonContainer > *:nth-child(2) {
  border: none;  
  box-shadow: none;
}

.buttonContainer > *:nth-child(3) {
  border: none;  
  box-shadow: none;
}
 
.divider {
  border-bottom: 1px solid #ccc;
}
.redemptionBubbleHolder{
  margin-top: 10px;
  margin-right: 20px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;

}
.redemptionBubble{
  background-color: #fff;
  font-family: 'productsans-black';
  font-size: 0.7rem;
  padding: 4px 8px;
  max-height: 2rem;
  border-radius: 2rem;
  /* border: 2px solid #000; */
  color: #000;
  width: 3rem;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);

}
