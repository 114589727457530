/* insightsScreen.module.css */

.sessionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.promosAndTableContainer {
  display: flex;
  align-items: flex-start;
}

.promosContainer {
  width: min-content; 
  overflow-y: auto; 
  border-right: 1px solid #e8e8e8; 
  padding: 0 20px; 
  height: calc(130vh - 200px);
  min-width: 340px;
}

.phoneCell {
  color: blue; 
}

.title {
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 24px;
  font-weight: 600;
}

.subtitle {
  padding-bottom: 10px;  
  color: rgba(0, 0, 0, 0.7);  
  font-size: 18px;  
  font-weight: 600;  
}

.tableContainer {
  flex: 1;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  padding-left: 20px;
}

.ant-table {
  table-layout: fixed;
  width: 100%;
}

.ant-table td,
.ant-table th {
  white-space: nowrap;  
  max-width: 150px;  
  overflow: hidden;
  text-overflow: ellipsis; 
}

.selected {
  background-color: lightgray;
}