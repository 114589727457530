.link-type-5 {
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .link-title {
    font-size: 24px;
    color: #000;
  }
  
  .link-subtitle {
    font-size: 16px;
    color: #000;
  }
  