.SectionHeader {padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;}

.sectionTitle{
    color: #1E1E1E;
    text-align: center;
    font-family: 'productsans-black';
    font-size: 2.75rem;
    font-style: normal;
    font-weight: normal; 
    line-height: 2.8rem;
    letter-spacing: 0.1125rem;
}
.sectionSubtitle{
    color: rgba(30, 30, 30, 0.50);

    text-align: center;
    font-family: 'productsans-regular';
    font-size: 1rem;
    font-style: normal;
    font-weight: normal; 
    line-height: normal;
    letter-spacing: 0.045rem;
}