.promosContainer {
    margin: 20px;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .card {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  
  .card h3 {
    margin-top: 0;
  }
  
  .card p {
    margin-bottom: 10px;
  }
  
  .card button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .card button:hover {
    background-color: #0056b3;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modal button {
    margin: 5px;
  }
  