.Promolist {
  background-color: #fff;
}

.headerContainer {
  display: flex;
  padding: 5px 15px 5px 0;
  flex-direction: row;
  width: 97%;
  justify-content: space-between;
}

.selectBox {
  width: 120px;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 1rem;
}
.scroll-item {
  min-width: 200px;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 5px;
}