.DealPageLoginTop {
    background-color: black;
    padding: 0.75rem;
    font-family: 'productsans-medium';
    display: flex;
    flex-direction: row;
    color: #FFF;
font-size: 1rem;
font-style: normal;
font-weight: normal;
line-height: normal;
letter-spacing: 0.0375rem;
gap:1rem;
justify-content: flex-end;

}

@media screen and (min-width: 400px){

    .DealPageLoginTop {
        background-color: black;
        padding: 0.75rem;
        font-family: 'productsans-medium';
        display: flex;
        flex-direction: row;
        color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0.0375rem;
    gap:1rem;
    justify-content: center;
    
    }
    
}