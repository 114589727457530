.CustomerWall {
    font-size: 1rem;
}

.MerchantCard {
    border: 1px solid black;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
}

.MerchantImage {
    max-width: 100px;
    margin-right: 10px;
}

a {
    color: black;
    text-decoration: none;
    font-size: 1rem;
}

a:hover {
    text-decoration: underline;
}
