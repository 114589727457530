.HowItWorks {color: #fff;}
.HowItWorks section{
    background-color: #1f1f1f;padding: 3rem; margin: 0;

}

.hiwcontentholder{

}
.hiwflexholder{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 2rem;
    justify-content: center;
    align-items: center;
}
.hiwcontentcontainer{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.hiwcontenttitle{color: #FFF;
    color: #FFF;
    font-family: 'productsans-black';
    font-size: 2.2rem;
    font-style: normal;
    font-weight: normal; 
    line-height: 2.4rem;
    letter-spacing: 0.09rem;
}
.hiwcontentsubtitle{
    color: rgba(255, 255, 255, 0.69);
    font-family: 'productsans-medium';
    font-size: 1rem;
    font-style: normal;
    font-weight: normal; 
    line-height: 1.2rem;
    letter-spacing: 0.045rem;
}

/* MOBILE ONLY* */
@media (min-width: 375px) and (max-width: 1024px){

    .hiwcontentholder{

    }
    .hiwflexholder{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
    
    }

}